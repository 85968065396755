<template>
    <div class="getfree">
        <div class="getfree-box">
            <div class="getfree-left">
                <div class="left-heder">
                    <span class="sign"></span>
                    <span class="hint">主商品</span>
                </div>
                <div class="left-bot">
                    <!-- {{goods.get_free_goods}} -->
                    <img :src="$img(goods.goods_image)" @click="goSku(goods)" @error="defaultGoodsImage" />
                    <p class="name">{{ goods.goods_name }}</p>
                    <ctrlShowText :info="goods" type="custom"  size="12px"  gap="10px" color="#909399"
                    :wesArr="['规格','效期','厂家']" :traArr="['规格','生产日期','厂家']"></ctrlShowText>
                    <template v-if="$store.getters.token">
                        <p class="pirce">￥{{ goods.sale_control.is_member_by && goods.sale_control.view_price ? goods.price
                            :
                            goods.sale_control.not_buy_msg }}</p>
                    </template>
                    <template v-else> 登录可见 </template>
                </div>
            </div>
            <div class="getfree-rigth">
                <div class="rigth-heder">换购专区</div>
               <div class="getfree-list"  ref="container" @mousedown="startScroll" @mouseup="stopScroll" @mouseleave="stopScroll"
               @mousemove="onScroll">
                <ul>
                    <li v-for="(item, index) in goods.get_free_goods?.getFreeGoods" :key="index" @click="goSku(item)">
                        <img :src="$img(item.goods.goods_image)" alt="" @error="imageError(index)" />
                        <p class="rigth-name">{{ item.goods.goods_name }}</p>
                        <ctrlShowText :info="item" type="custom"  size="12px"  gap="10px" color="#909399"
                            :wesArr="['规格','效期','厂家']" :traArr="['规格','生产日期','厂家']"></ctrlShowText>
                        <template v-if="$store.getters.token">
                            <p class="rigth-pirce"><span>换购价</span>￥{{ showFreePrice(item) }}</p>
                        </template>
                        <template v-else>
                            <p class="rigth-pirce"><span>换购价</span>￥登录可见</p>
                        </template>
                    </li>
                </ul>
               </div>
                
            </div>
     </div >
        <div class="getfree-b" v-if="goods.get_free_goods !== null">
            <p>换购活动：以下产品可任选{{ (goods.get_free_goods&&parseFloat(goods.get_free_goods.goods_range)>0)?(goods.get_free_goods.goods_range+'种'):'' }}</p>
            <template v-for="(exitem, index) in goods.get_free_goods.getFreeGoods" >
                <div class="get_free_goods_text" :key="index">
                    <p>{{ exitem.goods.goods_name }}</p>
                    <p>{{ returnFreeText(exitem) }}</p>
                </div>
            </template>
        </div>
        <div class="rigth-botm">
                    <div class="rigth-num">换购种类：{{ goods.get_free_goods?.getFreeGoods.length }}</div>
                    <div class="rigth-num-addon">
                        <shareCounter :number="goods.min_buy" :goodsSkuDetail="{
                            min_buy: goods.min_buy,
                            stock: goods.goods_stock,
                            package_num: goods.package_num || goods.min_buy,
                            is_member_by: goods.sale_control?.is_member_by,
                            view_price: goods.sale_control?.view_price,
                            sale_control_name: goods.sale_control?.sale_control_name
                        }" size="mini" @upnumber="upnumber"></shareCounter>
                        <template v-if="$store.getters.token">
                            <div class="addrad"
                                :class="goods.sale_control && goods.sale_control?.view_price ? 'addrad' : 'ban'"
                                @click="joinCart(goods)">加入购物车</div>
                        </template>
                        <template v-else>
                            <div class="addrad" @click="joinCart(goods)">加入购物车</div>
                        </template>
                    </div>
                </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import shareCounter from "@/components/shareCounter.vue"
import { onTip } from "@/utils/tips"
import ctrlShowText from "@/components/ctrl-show-text/ctrl-show-text.vue";
export default {
    components: {
        shareCounter,ctrlShowText
    },
    props: {
        goods: {
            required: true,
            type: Object
        }
    },
    data() {
        return {
            isScrolling: false,
            startX: 0,
            scrollLeft: 0,
            datainfo: [1, 2, 3, 4],
            number: 1
        }
    },
    computed: {
        ...mapGetters(["defaultGoodsImage", "storeWithoutAuthority"]),
    },
    methods: {
        returnFreeText(item){
            let bool = (this.goods.get_free_goods.is_ladder>0)
            if(bool){
                if(typeof item.ladder_json === 'string'){
                    item.ladder_json = JSON.parse(item.ladder_json)
                }
                let arr = [];
                item.ladder_json.forEach((item1,index)=>{
                    arr.push(`主商品x${item1.mainRang}可换${item1.rang}${item.goods.unit}每${item.goods.unit}￥${item1.rangPrice}`)
                })
                let str = arr.join('；')
                return str;
            }else{
                if(item.limit>0){
                    return `主商品x${item.count}可换${ item.num }${ item.goods.unit?item.goods.unit:'个' }每${item.goods.unit?item.goods.unit:'个'}￥${item.price}`;
                }else{
                    return `主商品x${item.count}可换${ item.num }${ item.goods.unit?item.goods.unit:'个' }每${item.goods.unit?item.goods.unit:'个'}￥${item.price},换购数量无上限`;
                }
            }
        },
        showFreePrice(info){
            if(!info||!info.sale_control){
                return "";
            }
            if(info.sale_control.is_member_by&&info.sale_control.view_price){
                let price = info.price;
                console.log("数据---",info);
                if (this.goods.get_free_goods.is_ladder > 0) {
                    price = info.goods.price;
                    if(typeof info.ladder_json === 'string'){
                        info.ladder_json = JSON.parse(info.ladder_json)
                    }
                    let num = this.number;
                    info.ladder_json.forEach((item1,index)=>{
                        if(num>=parseFloat(item1.mainRang)){
                            price = parseFloat(item1.rangPrice);
                            // if(info.limit===0){
                            //     price = parseFloat(item1.rangPrice);
                            // }else{
                            //     if(info.limit>=parseFloat(item1.mainRang)){
                            //         price = parseFloat(item1.rangPrice);
                            //     }
                            // }
                        }
                    })
                }
                return price
            }else{
                return info.sale_control.not_buy_msg
            }
        },
        /**
         * 图片加载失败
         */
        imageError(index) {
            this.goods.get_free_goods.getFreeGoods[index].goods.goods_image = this.defaultGoodsImage
        },
        // 加入购物车
        joinCart(item) {
            if (onTip(item.sale_control?.view_price, item.sale_control?.is_member_by, this, item.sale_control?.not_buy_msg)) return
            if (!this.goods.get_free_goods?.getFreeGoods.length > 0) {
                this.$message({
                    message: "暂无副商品",
                    type: "error"
                })
                return
            }
            this.$store
                .dispatch("cart/add_to_cart", {
                    site_id: item.site_id,
                    sku_id: item.sku_id,
                    num: this.number
                })
                .then((res) => {
                    if (res.code === 0) {
                        this.$message({
                            message: "加入购物车成功",
                            type: "success"
                        })
                    }
                })
                .catch((err) => {
                    if (err.message === "token不存在") {
                        this.$router.pushToTab("/login")
                    } else {
                        this.$message.error(err.message)
                    }
                })
        },
        goSku(item) {
            if (!this.$store.getters.token) {
                this.$router.pushToTab("/login")
                return
            }
            if (onTip(item.sale_control.view_price, item.sale_control.is_member_by, this, item.sale_control.not_buy_msg)) return
            this.$router.pushToTab("/sku-" + item.goods.sku_id)
        },
        upnumber(val) {
            this.number = val
        },
        startScroll(e) {
            this.isScrolling = true
            this.startX = e.pageX - this.$refs.container.offsetLeft
            this.scrollLeft = this.$refs.container.scrollLeft
        },
        stopScroll() {
            this.isScrolling = false
        },
        onScroll(e) {
            if (!this.isScrolling) return
            e.preventDefault()
            const x = e.pageX - this.$refs.container.offsetLeft
            const walk = (x - this.startX) * 2 // 滚动速度
            this.$refs.container.scrollLeft = this.scrollLeft - walk
        }
    }
}
</script>

<style lang="scss" scoped>
.getfree {
    background-image: linear-gradient(181.2deg, rgb(139 0 123 / 9%) -0.5%, rgb(254, 254, 254) 20.8%);

    .getfree-box {
        display: flex;
    }

    &-left {
        margin-right: 10px;
        width: 170px;
        padding: 10px;

        .left-heder {
            display: flex;
            align-items: center;
            line-height: 35px;

            .sign {
                display: inline-block;
                width: 3px;
                height: 15px;
                border-radius: 5px;
                background: darkmagenta;
                margin-right: 10px;
            }

            .hint {
                display: inline-block;
                font-size: 18px;
                font-weight: bold;
            }
        }

        .left-bot {
            cursor: pointer;

            img {
                width: 170px;
                height: 170px;
            }

            .name {
                margin-top: 10px;
                width: 170px;
                height: 45px;
                font-size: 20px;
                line-height: 22px;
                word-wrap: break-word;
                word-break: break-word;
                white-space: normal;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .pirce {
                font-size: 22px;
                color: red;
                font-weight: bolder;
            }
        }
    }

    &-rigth {
        flex: 1;
        padding: 10px;
        background: #fff;

        .rigth-heder {
            line-height: 35px;
            font-weight: bold;
        }
        .getfree-list{
            display: flex;
            overflow-x: auto;
            width: 343px;
            padding: 10px 0;
        }
        ul {
            display: flex;
            /* 显示x轴滚动条 */
            white-space: nowrap;
            cursor: pointer;
          
            position: relative;
            user-select: none;
            box-sizing: border-box;

            li {
                cursor: pointer;
                margin-right: 10px;
                width: 161px;

                img {
                    width: 170px;
                    height: 170px;
                    margin-bottom: 10px;
                }

                p {}

                .rigth-name {
                    width: 130px;
                    height: 35px;
                    line-height: 18px;
                    word-wrap: break-word;
                word-break: break-word;
                white-space: normal;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                }

                .rigth-pirce {
                    color: red;
                    font-weight: bolder;

                    & span {
                        font-size: 12px;
                        font-weight: 400;
                    }
                }
            }
        }

     
    }
    &-b{
        padding: 0px 10px 10px 10px;
    }
    .factory {
     
        line-height: 18px;
        height: 33px;
        word-wrap: break-word;
        word-break: break-word;
        white-space: normal;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
    }
  .factory-t{
        margin-top: 10px;
    }
    .rigth-botm {
            margin: 10px;
            display: flex;
            justify-content: space-between;

            .rigth-num {
                color: #909399;
            }

            .rigth-num-addon {
                display: flex;
            }

            .addrad {
                cursor: pointer;
                margin-left: 10px;
                padding: 3px 10px;
                border-radius: 5px;
                background: linear-gradient(44deg, #f17f3a 0%, rgba(240, 120, 82, 0.07) 28%, rgba(240, 115, 100, 0) 49%, rgba(247, 92, 111, 0.47) 73%, #ff427c 100%), #ff638b;
                color: #fff;
            }

            .ban {
                cursor: not-allowed;
                margin-left: 10px;
                padding: 3px 10px;
                border-radius: 5px;
                background: #dddddd6e;
                color: $base-color-whtie;
            }
        }
        ::-webkit-scrollbar {
        width: 5px;
        height: 8px;
        background-color: #f9f9f9;


    }

    ::-webkit-scrollbar-thumb {
        background-color: #8b007b17;
        border-radius: 10px;
        transition: background-color 0.2s ease;


    }  
}
.get_free_goods_text{
    width: 100%;
    display: flex;
    flex-direction: column;
    >p:nth-child(1){
        font-weight: bold;
    }
    >p:nth-child(2){
        font-size: 12px;
        color: #31BB6C;
    }
}
</style>