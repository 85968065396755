<script>
    import GoodsListItem from "@/components/goods-list-item/goods-list-item.vue";
    import Getfree from "./promotion/getfree.vue";
    import Bundling from "./promotion/bundling.vue";

    export default {
        name: "item",
        components: { GoodsListItem, Getfree, Bundling },
        props: {
            goods: {
                required: true,
                type: Object
            },
            activityInfo: {
                required: true,
                type: Object
            }
        },
        created() {

        },
        data() {
            return {
            }
        }
    }
</script>

<template>
    <div class="item" v-if="['seckill', 'discount', 'wholesale'].includes(activityInfo.type)">
        <GoodsListItem :showLimit="'seckill'.includes(activityInfo.type)" :goods="activityInfo.listItemAdepter ? activityInfo.listItemAdepter(goods) :goods" :up-down-layout="true" />
    </div>
    <div class="allgetfree" v-else-if="activityInfo.type === 'getfree'">
       <Getfree :goods="goods" />
    </div>
    <div class="allbundling" v-else-if="activityInfo.type === 'bundling'">
        <Bundling :goods="goods" />
    </div>
</template>

<style scoped lang="scss">
.allbundling{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border: 1px solid #f0f0f0b8;
    transition: opacity 0.3s ease, transform 0.3s ease;
    transform: scale(1); /* 初始大小 */
}
.allbundling:hover{
    opacity: 1;
    transform: scale(1.01); /* 放大效果 */
}
.allgetfree{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border: 1px solid #f0f0f0b8;
    transition: opacity 0.3s ease, transform 0.3s ease;
    transform: scale(1); /* 初始大小 */
   
}
.allgetfree:hover{
    opacity: 1;
    transform: scale(1.01); /* 放大效果 */
}
.item {
    width: 200px;
    padding: 22px 12px 14px 12px;
    transition: box-shadow 0.2s;
    border: 1px solid #F0F0F0;
    cursor: pointer;
    &:hover {
        z-index: 2;
        -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
        box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
        -webkit-transform: translate3d(0, -2px, 0);
        transform: translate3d(0, -2px, 0);
    }
}
</style>
