<template>
    <div class="bundling_box">
         <div>
            <div class="bundling_title">
                <p>{{ goods.bl_name }}</p>
            </div>
            <div class="bundling_content">
                 <div class="bundling_goods" ref="bundlingContainer"   @mousedown="startScroll" @mouseup="stopScroll" @mouseleave="stopScroll" @mousemove="onScroll">
                      <template v-for="(item, index) in goods.bundlingGoods">
                        <div class="bundling_goods_item" @click="goSku(item)">
                            <div>
                                <img :src="$img(item.sku_image)" alt="">
                            </div>
                            <div>
                                <p class="p1">{{ item.sku_name }}</p>
                                <template v-if="$store.getters.token">
                                    <p class="p2" v-if="item.sale_control.view_price || item.sale_control.is_member_by">￥{{ item.promotion_price }}</p>
                                    <p class="p4" v-else>￥{{ item.sale_control.not_buy_msg }}</p>
                                </template>
                                <template v-else>
                                    <p class="p4">￥登录可见</p>
                                </template>
                                <p class="p3">套餐数量：{{ item.num }}</p>
                            </div>
                            <ctrlShowText :info="item" type="custom" size="18px" gap="6px"
                            :wesArr="['规格','数量','效期','厂家']" :traArr="['规格','数量','生产日期','厂家']"></ctrlShowText>
                        </div>
                        <div class="symbol_box" v-if="goods.bundlingGoods&&goods.bundlingGoods.length>1&&(goods.bundlingGoods.length-index)>1">
                             <p>+</p>
                        </div>
                      </template>
                 </div>
                 <div class="symbol_box">
                     <p>=</p>
                 </div>
                 <div class="bundling_preice">
                      <p>套餐价：</p>
                      <template v-if="$store.getters.token">
                          <template v-if="goods.sale_control.is_member_by && goods.sale_control.view_price">
                            <p>￥{{ goods.bl_price }}</p>
                            <p>可省:￥{{ goods.goods_money }}</p>
                          </template>
                          <template v-else>
                            <p>{{ goods.sale_control.not_buy_msg }}</p>
                            <p>{{ goods.sale_control.not_buy_msg }}</p>
                          </template>
                      </template>
                      <template v-else>
                        <p>￥登录可见</p>
                        <p>可省:￥登录可见</p>
                      </template>
                      <template v-if="$store.getters.token">
                        <div :class="goods.sale_control && goods.sale_control?.view_price ? 'join_btn' : 'join_btn_warning'" @click="joinCart(goods)">
                           <p>加入购物车</p>
                        </div>
                      </template>
                      <template v-else>
                        <div class="join_btn" @click="joinCart(goods)">
                           <p>加入购物车</p>
                        </div>
                      </template>
                 </div>
            </div>
         </div>
    </div>
</template>

<script>
import { onTip } from "@/utils/tips"
import { joinCart } from "@/api/promotion/bundling"
import ctrlShowText from "@/components/ctrl-show-text/ctrl-show-text.vue";
export default {
    components: {ctrlShowText},
    props: {
        goods: {
            required: true,
            type: Object
        },
    },
    data() {
        return {
            number: 1,
            isScrolling: false,
            startX: 0,
            scrollLeft: 0,
        }
    },
    methods: {
        goSku(item) {
            if (!this.$store.getters.token) {
                this.$router.pushToTab("/login")
                return
            }
            if (onTip(item.sale_control.view_price, item.sale_control.is_member_by, this, item.sale_control.not_buy_msg)) return
            this.$router.pushToTab("/sku-" + item.sku_id)
        },
        // bundingGoodsJoinCart(goods) {
        //     console.log(goods)
        //     joinCart({
        //         id: goods.bl_id,
        //         num: 1
        //     })
        // },
        // 加入购物车
        joinCart(item) {
            if (onTip(item.sale_control?.view_price, item.sale_control?.is_member_by, this, item.sale_control?.not_buy_msg)) return
            joinCart({
                id: item.bl_id,
                num: this.number
            }).then((res) => {
                if (res.code === 0) {
                    this.$message({
                        message: "加入购物车成功",
                        type: "success"
                    })
                }
            }).catch((err) => {
                if (err.message === "token不存在") {
                    this.$router.pushToTab("/login")
                } else {
                    this.$message.error(err.message)
                }
            })
        },
        startScroll(e) {
            this.isScrolling = true;
            this.startX = e.pageX - this.$refs.bundlingContainer.offsetLeft;
            this.scrollLeft = this.$refs.bundlingContainer.scrollLeft;
        },
        stopScroll() {
            this.isScrolling = false;
        },
        onScroll(e) {
            if (!this.isScrolling) return;
            e.preventDefault();
            const x = e.pageX - this.$refs.bundlingContainer.offsetLeft;
            const walk = (x - this.startX) * 2; // 滚动速度
            this.$refs.bundlingContainer.scrollLeft = this.scrollLeft - walk;
        },
    },
}
</script>

<style lang="scss" scoped>
.bundling_box{
   padding: 6px;
   box-sizing: border-box;
   background-color: #B51F12;
   display: flex;
   flex-direction: column;
   position: relative;
   .bundling_title{
      box-sizing: border-box;
      display: flex;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;
      width: 421px;
      height: 63px;
      background: linear-gradient( 180deg, #FE8235 0%, #F33125 100%);
      border-radius: 9px;
      padding: 6px;
      box-sizing: border-box;
      >p{
        font-weight: bold;
        font-size: 30px;
        color: #FFFFFF;
        line-height: 39px;
      }                      
   }
   .bundling_content{
    background: #FFFFFF;
    border-radius: 5px;
    padding: 66px 10px 10px 10px;
    box-sizing: border-box;
    display: flex;
    height: 100%;
   }
   .bundling_goods{
      overflow: auto;
      flex-shrink: 0;
      display: flex;
      width: 410px;
      padding-bottom: 12px;
      box-sizing: border-box;
      ::-webkit-scrollbar {  
        height: 5px;
      }  

      ::-webkit-scrollbar-thumb {  
        background-color: #3AB678;
        border-radius: 5px;
      }
      scrollbar-width: thin;
      scrollbar-color: #3AB678 transparent; 
   }
   .bundling_goods_item{
       display: flex;
       flex-direction: column;
       gap: 6px;
       width: 168px;
       overflow: hidden;
       flex-shrink: 0;
       >div:nth-child(1){
        width: 168px;
        height: 168px;
        border-radius: 3px;
        overflow: hidden;
        border: 1px solid #E5E5E5;
        box-sizing: border-box;
        >img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
       }
       >div:nth-child(2){
         display: flex;
         flex-direction: column;
         gap: 6px;
         >.p1{
            font-weight: bold;
            font-size: 22px;
            color: #3D3D3D;
            line-height: 31px;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100%;
            white-space: nowrap;
         }
         >.p2{
            font-weight: bold;
            font-size: 28px;
            color: #FF0000;
            line-height: 19px;
         }
         >.p3{
            font-weight: 400;
            font-size: 18px;
            color: #FB7100;
            line-height: 25px;
         }
         >.p4{
            font-weight: bold;
            font-size: 28px;
            color: #FF0000;
            line-height: 19px;
         }
       }
   }
   .symbol_box{
       display: flex;
       align-items: center;
       height: 168px;
       margin: 0 26px;
       p{
        color: #6E6E6E;
        font-weight: bold;
        font-size: 24px;
       }
   }
   .bundling_preice{
       width: 162px;
       display: flex;
       flex-direction: column;
       >p:nth-child(1){
          color: #3D3D3D;
          font-size: 24px;
          line-height: 34px;
          font-weight: bold;
          margin-bottom: 30px;
       }
       >p:nth-child(2){
          color: #FF0000;
          font-size: 30px;
          line-height: 34px;
          margin-bottom: 12px;
          font-weight: bold;
        }
       >p:nth-child(3){
        font-weight: 400;
        font-size: 18px;
        color: #6E6E6E;
        line-height: 25px;
        margin-bottom: 12px;
       }
       .join_btn{
        cursor: pointer;
        width: 109px;
        height: 40px;
        border-radius: 9px;
        border: 1px solid #FF0000;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        >p{
            font-weight: 500;
            font-size: 18px;
            color: #FF0000;
            line-height: 19px;
        }
       }
       .join_btn_warning{
        cursor: default;
        width: 109px;
        height: 40px;
        border-radius: 9px;
        border: 1px solid #dddddd6e;
        background-color: #dddddd6e;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        >p{
            font-weight: 500;
            font-size: 18px;
            color: white;
            line-height: 19px;
        }
       }
   }
   >div:nth-child(1){
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 6px;
    box-sizing: border-box;
    height: 100%;
   }
}

</style>
