<template>
    <div class="activity-list" v-loading="loading">
        <div style="display: flex;flex-wrap: wrap;gap: 10px">
            <activity-item v-for="(item, index) in dataList" :key="item.goods_id" :goods="item" :activity-info="info"></activity-item>
        </div>
        <el-pagination v-if="dataList.length" style="padding-right: 30px; margin-top: 20px" background :pager-count="5"
            :total="total" prev-text="上一页" next-text="下一页" :current-page.sync="currentPage" :page-size.sync="pageSize"
            @size-change="handlePageSizeChange" @current-change="handleCurrentPageChange"></el-pagination>
        <div v-else class="empty">
            <p>暂无数据</p>
        </div>
    </div>
</template>
<script>
import { onTip } from "@/utils/tips"
import { mapGetters } from "vuex"
import shareCounter from "@/components/shareCounter.vue"
import GoodsListItem from "@/components/goods-list-item/goods-list-item.vue"
import ActivityItem from "./activity-item.vue";
export default {
    name: "index.vue",
    components: { GoodsListItem, shareCounter, ActivityItem },
    props: {
        info: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    created() {
    },
    computed: {
        ...mapGetters(["token", "storeWithoutAuthority"])
    },
    data() {
        return {
            dataList: [],
            total: 0,
            currentPage: 1,
            pageSize: 10,
            loading: false,
            number:1
        }
    },
    methods: {
        upnumber(val){
              this.number=val
         },
            // 加入购物车
            joinCart(item) {
            this.$store
                .dispatch("cart/add_to_cart", {
                    site_id: item.site_id,
                    sku_id: item.id,
                    num: this.number
                })
                .then((res) => {
                    if (res.code === 0) {
                        this.$message({
                            message: "加入购物车成功",
                            type: "success"
                        })
                    }
                })
                .catch((err) => {
                    if (err.message === "token不存在") {
                        this.$router.pushToTab("/login")
                    } else {
                        this.$message.error(err.message)
                    }
                })
        },
        goSku(item) {
            if (!this.token) {
                this.$router.pushToTab("/login")
                return
            }
            if (onTip(item.control.show_price,item.sale_control.is_member_by, this, item.sale_control.not_buy_msg)) return

            this.$router.pushToTab({ path: this.info.getDetailUrl(item) })
            // <!-- @click.native="$router.pushToTab({ path: info.getDetailUrl(item) })" -->
        },
        // 请求秒杀商品
        async requestList() {
            this.loading = true
            if (this.info.type) {
                let page = this.currentPage
                let page_size = this.pageSize
                const data = {
                    page,
                    page_size
                }

                try {
                    const res = await this.info.listApi(data)
                    if (res.code === 0) {
                        // 处理组合套餐数据结构
                        if(this.info.type =="bundling"){
                            this.dataList=res.data.list.data
                        }else{
                            this.dataList = res.data.list
                        }
                        this.total = res.data.count
                    }
                    this.loading = false
                } catch (e) {
                    this.loading = false
                    this.dataList = []
                }
            } else {
                this.loading = false
                this.dataList = []
            }
        },
        handlePageSizeChange(page) { },
        resetPage() {
            this.currentPage = 1
        },
        handleCurrentPageChange() {
            this.requestList()
        }
    },
    watch: {
        info: {
            handler(nValue, oValue) {
                if (nValue) {
                    nValue.type !== oValue.type && this.resetPage()
                    this.requestList()
                }
            },
        }
    }
}
</script>
<style scoped lang="scss">
.activity-list {
    min-height: 200px;
}
.cart-layout {
                    margin: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .cart-num {
                        margin-right: 10px;
                        position: relative;
                        width: 30%;
                        border-radius: 4px;
                        display: flex;
                        border: 1px solid #ccc;
                        justify-content: center;
                        align-items: center;

                        span {
                            z-index: 1;
                            position: absolute;
                            cursor: pointer;
                            width: 20px;
                            text-align: center;
                        }

                        span:first-child {
                            left: 0;
                        }

                        span:last-child {
                            right: 0;
                        }

                        .number {
                            .el-input {
                                .el-input__inner {
                                    text-align: center;
                                }
                            }
                        }
                    }
                   ::v-deep .el-input-number--mini{
                        width: 95px !important;
                    }
                    .cart-add {
                        cursor: pointer;
                        border: 1px solid $base-color;
                        color: $base-color;
                        border-radius: 4px;
                        padding: 2px 10px;
                    }
                }
::v-deep .goods-info {
    justify-content: flex-start !important;
}

.empty {
    text-align: center;
}
</style>
